<template>
  <div id="date-page">
    <TitleHelper :title="'Outlived today: ' + daysAge + ' days old'"></TitleHelper>

    <div class="select">
        <select v-model="day" @change="getDate()">
            <option v-for="option in dayOptions" :value="option.value" :key="option.value">
            {{ option.text }}
            </option>
        </select>
        <select v-model="month" @change="generateDayOptions(); getDate()">
            <option v-for="option in monthOptions" :value="option.value" :key="option.value">
            {{ option.text }}
            </option>
        </select>
        <select v-model="year" @change="getDate()">
            <option v-for="option in yearOptions" :value="option.value" :key="option.value">
            {{ option.text }}
            </option>
        </select>
    </div>
    <div>
        <button @click="incYear(); getDate()">+</button>
        <button @click="decYear(); getDate()">-</button>
    </div>
    <div class="spacer"></div>
    <span style="font-size: xx-large; font-weight: bold;">{{daysAge}} days old</span>
    <div></div>
    <div class="display">
      <table style="width: 100%;">
        <tr v-for="item in data" :key="item._id" style="width: 100%; height: 100px;">
            <td style="width: 10%;"><span v-if="item.doc.image"><img :src="item.doc.image" height="100" style="filter:grayscale(100%); opacity: 0.5;"></span></td>
            <td style="width: 70%; font-size: xx-large; font-family: 'calendas'">
              <a style="font-size: 120%; letter-spacing: 0.2px; line-height: 0.9;" :href="'http://en.wikipedia.org/?curid='+item.doc.articleId">{{item.doc.articleName}}</a>
              <div style="font-size: small; font-family: 'Avenir'">{{item.doc.articleDesc}}</div></td>
            <td style="width: 20%;">
              <span v-if="parseInt(item.doc.daysAlive) < daysAge">{{item.diff}} days ago</span>
              <span v-else-if="parseInt(item.doc.daysAlive) == daysAge">outliving today</span>
              <span v-else>{{item.diff}} days from now</span>
            </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "Deathlist",
  beforeCreate: function() {
    document.body.className = 'deathlist';
  },
  data() {
    return {
        values: config,
        data: [],
        errorValue: null,
        year: "2020",
        month: 4,
        day: 1,
        maxYear: '2020',
        monthOptions: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 }
        ],
        monthLengths: [31,28,31,30,31,30,31,31,30,31,30,31],
        leapMonthLengths: [31,29,31,30,31,30,31,31,30,31,30,31],
        yearOptions:[],
        dayOptions:[],
        daysAge: null,
    };
  },
  mounted() {
      var d = new Date();
      this.day = d.getDate();
      this.month = d.getMonth()+1;
      this.year = d.getFullYear().toString();
      this.maxYear = d.getFullYear().toString();
      if (this.$route.params.pathMatch && this.$route.params.pathMatch.split('-').length == 3) {
          let d = this.$route.params.pathMatch.split('-');
          this.year = d[0];
          this.month = parseInt(d[1]);
          this.day = parseInt(d[2]);
      }
      this.generateDayOptions();
      this.generateYearOptions();
      console.log(this.maxYear);
      this.getDate();
  },
  components: {
    TitleHelper,
  },
  methods: {
    incYear: function() {
        var y = parseInt(this.year)
        var now = new Date();
        if (this.year.includes('BC')) {
            y -= 1;
            if (y == 0) {
                this.year = '1';
            } else {
                this.year = y.toString()+'BC';
            }
        } else if (y+1 > now.getFullYear()) {
            this.year = now.getFullYear().toString();
        } else {
            y += 1;
            this.year = y.toString();
        }
    },
    decYear: function() {
        var y = parseInt(this.year)
        if (this.year.includes('BC')) {
            y += 1;
            this.year = y.toString() + 'BC';
        } else {
            y -= 1;
            if (y == 0) {
                this.year = '1BC';
            } else {
                this.year = y.toString();
            }
        }
    },
    isYearPrime: function() {
        if (parseInt(this.year)%400 == 0) {
            return true;
        } else if (parseInt(this.year)%100 ==0) {
            return false;
        } else if (parseInt(this.year)%4 ==0) {
            return true;
        } else {
            return false;
        }
    },
    generateDayOptions: function() {
        var monthLength = 0;
        this.dayOptions = [];
        if (this.isYearPrime(this.Year)) {
            monthLength = this.leapMonthLengths[this.month-1];
        } else {
            monthLength = this.monthLengths[this.month-1];
        }
        for (var d = 1; d <= monthLength; d += 1) {
            this.dayOptions.push( { text: d , value: d } );
        }
    },
    generateYearOptions: function() {
        this.yearOptions = [];
        var label = ''
        var val = ''
        for (var y = this.maxYear; y > 1880; y -= 1) {
            label = y.toString()
            val = y.toString()
            this.yearOptions.push( { text: label, value: val })
        }
    },
    getDate: function() {

      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      var birthDate = new Date(parseInt(this.year), this.month-1, this.day)
      var today = new Date();
      var todayDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
      var diff = Math.ceil((todayDate - birthDate) / (1000*60*60*24));
      this.daysAge = diff;

      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/deathlist/" + diff.toString();
      axios
        .get(url,body,config)
        .then(response => {
            this.data = response["data"];
        })
        .catch(error => {
            this.errorValue = error;
        })
      if (this.$route.fullPath != '/deathlist/' + this.year + '-' + this.month.toString() + '-' + this.day.toString()) {
          this.$router.push({ path: '/deathlist/' + this.year + '-' + this.month.toString() + '-' + this.day.toString() })
      }
    }
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@font-face {
    font-family: 'calendas';
    src: url('fonts/kjv1611-webfont.woff') format('woff');
    src: url('fonts/kjv1611-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}

#date-page {
  padding: 8px;
  min-height: calc(100vh - 16px);
  background-color: #23233f;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

a {
  text-decoration: none;
  color: #456bda;
}

body {
  margin: 0px;
}

button {
  background-color: teal;
  color: white;
  border: 0px;
  width: 25%;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
}

button[disabled] {
  background-color: #999999;
}

button[disabled]:hover {
  background-color: inherit;
  box-shadow: inherit;
}

button:hover {
  background-color: #2bb7c4;
  box-shadow: 3px 3px 2px 0px #cccccc;
}

.tab-button {
  border-radius: 3px 3px 0px 0px;
  padding: 6px 10px;
  cursor: pointer;
  background: #f0f0f0;
  color: black;
  margin: 0px 4px 0px 4px;
  width: 15%;
}

.tab-button:hover {
  background: #e0e0e0;
  box-shadow: none;
}
.tab-button.active {
  background: teal;
  color: white;
  box-shadow: none;
}

.tab {
  border-top: 1px solid #cccccc;
  height: calc(100vh - 91px);
}

button:focus {
  outline:0;
}

select {
  width: 25%;
  height: 30px;
  margin: 10px;
}

div.spacer {
  height:30px;
}
div.error {
  margin: 20px;
  padding: 10px;
  background-color: #ff9999;
  border: 2px red;
}
</style>

